import React, { useRef } from "react";

import MaterialTable from "material-table";

import axios from "config/axios";
import { AlertSeverity, ManageParams } from "types";
import { useHistory, useParams } from "react-router";
import { IconButton, Typography } from "@material-ui/core";
import { ArrowRight, Remove } from "@material-ui/icons";

//import useStyles from "./styles";

interface Props {
  alterAlert: (msg: string, severity: AlertSeverity) => void;
}

const PlaceDoors: React.FC<Props> = (props) => {
  //const classes = useStyles();
  const { alterAlert } = props;

  const history = useHistory();

  const tableRef = useRef<any>();

  const params = useParams<ManageParams>();

  return (
    <div>
      <MaterialTable
        options={{
          exportButton: true,
          grouping: true,
          search: true,
          pageSize: 17,
          pageSizeOptions: [8, 17, 32],
        }}
        tableRef={tableRef}
        actions={[
          {
            icon: "refresh",
            tooltip: "Recarregar dados",
            isFreeAction: true,
            onClick: () =>
              tableRef.current && tableRef?.current?.onQueryChange(),
          },
        ]}
        style={{ fontFamily: "Nunito Sans" }}
        columns={[
          {
            title: "#",
            field: "index",
            type: "string",
            editable: "never",
            render: (data) => {
              return <Typography>{data.index + 1}</Typography>;
            },
          },
          {
            title: "createdAt",
            field: "createdAt",
            type: "date",
            hidden: true,
            defaultSort: "asc",
          },
          {
            title: "_id",
            field: "_id",
            type: "string",
            hidden: true,
          },
          {
            title: "Armário",
            field: "board.name",
            type: "string",
            editable: "never",
          },
          {
            title: "Código da porta",
            field: "cod",
            type: "string",
          },
          {
            title: "Alugado",
            field: "rented",
            type: "boolean",
            hidden: true,
          },
          {
            title: "Em uso",
            field: "user.name",
            type: "string",
            render: (data: any) => {
              return (
                <Typography>
                  {data.rented ? data?.user?.name : <Remove />}
                </Typography>
              );
            },
          },
          {
            title: "",
            editable: "never",
            render: (data) => {
              return (
                <IconButton
                  onClick={() =>
                    history.push(
                      `/dashboard/door/${data._id}/${params.placeId}`
                    )
                  }
                  color="secondary"
                  size="small"
                  style={{ backgroundColor: "#333" }}
                >
                  <ArrowRight />
                </IconButton>
              );
            },
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let { page, pageSize, orderBy, orderDirection, search } = query;
            console.log(query);
            axios
              .post(`/licensed/place/doors`, {
                page,
                pageSize,
                orderBy,
                orderDirection,
                search,
                placeId: params.placeId,
              })
              .then(({ data }) => {
                resolve({
                  data: data.docs,
                  page: data.page - 1,
                  totalCount: data.totalDocs,
                });
              })
              .catch((error) => {
                console.log(error);
                switch (error.response.data.status) {
                  default:
                    alterAlert("Problema ao carregar dados", "error");
                    break;
                }
                reject("Problema ao carregar dados");
              });
          })
        }
        localization={{
          header: {
            actions: "Ações",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "registros",
            labelRowsPerPage: "Registros por página:",
            firstAriaLabel: "Primeira",
            firstTooltip: "Primeira",
            previousAriaLabel: "Anterior",
            previousTooltip: "Anterior",
            nextAriaLabel: "Próxima",
            nextTooltip: "Próxima",
            lastAriaLabel: "Última",
            lastTooltip: "Última",
          },
          toolbar: {
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar",
            exportCSVName: "Exportar CSV",
            exportPDFName: "Exportar PDF",
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
          },
          body: {
            emptyDataSourceMessage: "Sem informações",
          },
          grouping: {
            groupedBy: "Agrupado por:",
            placeholder: "Arraste um cabeçalho para agrupar",
          },
        }}
        title="Portas do local"
      />
    </div>
  );
};

export default PlaceDoors;
