import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import MaterialTable from "material-table";

import axios from "config/axios";
import {
  AlertSeverity,
  Log,
  ManageParams,
  PlaceStatistics as PlaceStatisticsType,
} from "types";
import { useParams } from "react-router";
import {
  Button,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowRight, CloudDownload, Receipt } from "@material-ui/icons";

import useStyles from "./styles";
import { calculatePrice } from "functions/calculatePrice";
import { useFormik } from "formik";
import * as yup from "yup";
import download from "downloadjs";
import { KeyboardDatePicker } from "@material-ui/pickers";

interface Props {
  alterAlert: (msg: string, severity: AlertSeverity) => void;
}

const useReportValidate = yup.object({
  minDate: yup.date().required("Entre com a data inicial").nullable(),
  maxDate: yup.date().required("Entre com a data final").nullable(),
  email: yup.string().email("Email inválido"),
});

interface FormikUseReport {
  minDate: null | Date;
  maxDate: null | Date;
  email: string;
}

const PlaceStatistics: React.FC<Props> = (props) => {
  const [placeStatistics, setPlaceStatistics] = useState<PlaceStatisticsType>(),
    [loading, setLoading] = useState(false),
    [loadingReport, setLoadingReport] = useState(false);

  //const classes = useStyles();
  const { alterAlert } = props;

  const tableRef = useRef<any>();

  const classes = useStyles();

  const params = useParams<ManageParams>();

  const getPlaceStatistics = useCallback(async () => {
    setLoading(true);
    axios
      .get(`/licensed/place/statistics/${params.placeId}`)
      .then(({ data }) => {
        console.log(data);
        setLoading(false);
        setPlaceStatistics(data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            alterAlert("Problema ao carregar dados da porta", "error");
            break;
        }
      });
  }, [params, alterAlert]);

  useEffect(() => {
    getPlaceStatistics();
  }, [getPlaceStatistics]);

  const formatMonthRevenues = useMemo(() => {
    if (!placeStatistics) return calculatePrice(0);
    return calculatePrice(placeStatistics?.monthRevenues || 0);
  }, [placeStatistics]);

  const formatByDoorRevenue = useMemo(() => {
    if (!placeStatistics) return calculatePrice(0);
    return calculatePrice(placeStatistics?.byDoorRevenue || 0);
  }, [placeStatistics]);

  const generateUseReport = async (values: FormikUseReport) => {
    const { minDate, maxDate, email } = values;
    setLoadingReport((oldState) => !oldState);
    axios
      .post(
        "/licensed/place/use/report",
        {
          minDate,
          maxDate,
          email,
          placeId: params.placeId,
        },
        {
          responseType: "blob",
        }
      )
      .then((val) => {
        setLoadingReport((oldState) => !oldState);
        let { data } = val;
        download(
          data,
          `relatórioLockyt-${new Date().getTime()}`,
          "application/pdf"
        );
      })
      .catch((error) => {
        setLoadingReport((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            alterAlert("Problema ao gerar relatórío, tente novamente", "error");
            break;
        }
      });
  };

  const reportFormik = useFormik<FormikUseReport>({
    initialValues: {
      minDate: null,
      maxDate: null,
      email: "",
    },
    validationSchema: useReportValidate,
    onSubmit: generateUseReport,
  });

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress size={40} />
      </div>
    );
  }

  return (
    <div>
       <Paper className={classes.paperMoney}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="h5">
              Faturamento do mês: <b>{formatMonthRevenues}</b>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Faturamento por porta/mês: <b>{formatByDoorRevenue}</b>
            </Typography>
          </Grid>
        </Grid>
      </Paper> 

      <Paper className={classes.paper} style={{ marginBottom: 20 }}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={3}
        >
          <Grid item style={{ marginTop: 8 }}>
            <Icon>
              <Receipt />
            </Icon>
          </Grid>
          <Grid item>
            <Typography variant="h5">Gerar relatório</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <KeyboardDatePicker
              margin="normal"
              id="filterMinDate"
              label="Data inicial"
              required
              error={
                reportFormik.touched.minDate &&
                Boolean(reportFormik.errors.minDate)
              }
              helperText={
                reportFormik.touched.minDate && reportFormik.errors.minDate
              }
              format="dd/MM/yyyy"
              value={reportFormik.values.minDate}
              invalidDateMessage="Data inválida"
              maxDate={new Date()}
              maxDateMessage="Não é possível ser uma data futura"
              onChange={(date) => {
                if (!date) {
                  return reportFormik.setFieldValue("minDate", null);
                }
                date = new Date(date.setHours(23));
                date = new Date(date.setMinutes(59));
                date.getTime();
                reportFormik.setFieldValue("minDate", date);
              }}
            />
          </Grid>
          <Grid item>
            <KeyboardDatePicker
              margin="normal"
              id="filterMaxDate"
              label="Data final"
              required
              format="dd/MM/yyyy"
              value={reportFormik.values.maxDate}
              error={
                reportFormik.touched.maxDate &&
                Boolean(reportFormik.errors.maxDate)
              }
              helperText={
                reportFormik.touched.maxDate && reportFormik.errors.maxDate
              }
              maxDate={new Date()}
              maxDateMessage="Não é possível ser uma data futura"
              invalidDateMessage="Data inválida"
              onChange={(date) => {
                if (!date) {
                  return reportFormik.setFieldValue("maxDate", null);
                }
                date = new Date(date.setHours(23));
                date = new Date(date.setMinutes(59));
                date.getTime();
                reportFormik.setFieldValue("maxDate", date);
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email do usuário (opcional)"
              value={reportFormik.values.email}
              onChange={reportFormik.handleChange}
              error={
                reportFormik.touched.email && Boolean(reportFormik.errors.email)
              }
              helperText={
                reportFormik.touched.email && reportFormik.errors.email
              }
              style={{ marginTop: "5%" }}
              inputProps={{ style: { padding: 13 } }}
            />
          </Grid>
          <Grid item>
            <div className={classes.wrapper}>
              <Button
                onClick={() => reportFormik.handleSubmit()}
                endIcon={<CloudDownload />}
                variant="contained"
                color="secondary"
                disabled={loading || loadingReport}
              >
                Gerar relatório
              </Button>
              {loadingReport && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Paper>
      <MaterialTable
        options={{ exportButton: true, grouping: true, search: true }}
        tableRef={tableRef}
        actions={[
          {
            icon: "refresh",
            tooltip: "Recarregar dados",
            isFreeAction: true,
            onClick: () =>
              tableRef.current && tableRef?.current?.onQueryChange(),
          },
        ]}
        style={{ fontFamily: "Nunito Sans" }}
        columns={[
          {
            title: "_id",
            field: "_id",
            type: "string",
            hidden: true,
            editable: "never",
          },
          {
            title: "Armário",
            field: "board.name",
            type: "string",
            editable: "never",
          },
          {
            title: "Código",
            field: "door.cod",
            type: "string",
            editable: "never",
          },
          {
            title: "Encerrado",
            field: "ended",
            type: "boolean",
            editable: "never",
          },
          {
            title: "Início do aluguel",
            field: "rented",
            type: "datetime",
            editable: "never",
            defaultSort: "desc",
          },
          {
            title: "Fim do aluguel",
            field: "ended",
            type: "datetime",
            editable: "never",
            defaultSort: "desc",
          },
            {
            title: "Valor",
            type: "string",
            editable: "never",
            render: (data: Log) => {
              return <Typography>{calculatePrice(data.finalPrice)}</Typography>;
            },
          }, 
          {
            title: "",
            editable: "never",
            render: (data) => {
              return (
                <IconButton
                  onClick={() => console.log("asdas")}
                  color="secondary"
                  size="small"
                  style={{ backgroundColor: "#333" }}
                >
                  <ArrowRight />
                </IconButton>
              );
            },
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let { page, pageSize, orderBy, orderDirection, search } = query;
            axios
              .post(`/licensed/place/logs`, {
                page,
                pageSize,
                orderBy,
                orderDirection,
                search,
                placeId: params.placeId,
              })
              .then(({ data }) => {
                resolve({
                  data: data.docs,
                  page: data.page - 1,
                  totalCount: data.totalDocs,
                });
              })
              .catch((error) => {
                console.log(error);
                switch (error.response.data.status) {
                  default:
                    alterAlert("Problema ao carregar dados", "error");
                    break;
                }
                reject("Problema ao carregar dados");
              });
          })
        }
        localization={{
          header: {
            actions: "Ações",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "registros",
            labelRowsPerPage: "Registros por página:",
            firstAriaLabel: "Primeira",
            firstTooltip: "Primeira",
            previousAriaLabel: "Anterior",
            previousTooltip: "Anterior",
            nextAriaLabel: "Próxima",
            nextTooltip: "Próxima",
            lastAriaLabel: "Última",
            lastTooltip: "Última",
          },
          toolbar: {
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar",
            exportCSVName: "Exportar CSV",
            exportPDFName: "Exportar PDF",
            searchTooltip: "Pesquisar",
            searchPlaceholder: "Pesquisar",
          },
          body: {
            emptyDataSourceMessage: "Sem informações",
          },
          grouping: {
            groupedBy: "Agrupado por:",
            placeholder: "Arraste um cabeçalho para agrupar",
          },
        }}
        title="Histórico de uso"
      />
      <div className={classes.chartContainer}>
        <iframe
          style={{ border: "none" }}
          title="Gráfico abertura/mês"
          width="100%"
          height="480"
          src="https://charts.mongodb.com/charts-project-0-eaybw/embed/charts?id=d049a5c9-1ea4-4a59-bb74-fb072e382539&maxDataAge=3600&theme=light&autoRefresh=true"
        ></iframe>
      </div>
    </div>
  );
};

export default PlaceStatistics;
