import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#05b5a9",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#7f6eb2",
      contrastText: "#ffffff",
    },
    action: {
      active: "#0c2c7b",
      hover: "#ddd",
      hoverOpacity: 0.1,
    },
  },
});

export default theme;
