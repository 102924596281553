import React, { useState, useEffect, useCallback } from "react";

import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  IconButton,
  Grid,
  CircularProgress,
} from "@material-ui/core";

import Alert from "components/Alert";

import axios from "config/axios";
import { AlertSeverity, Place } from "types";

import useStyles from "./styles";
import { useHistory } from "react-router";

import * as yup from "yup";
import { useFormik } from "formik";
import { Add } from "@material-ui/icons";

const validationSchema = yup.object({
  cod: yup
    .string()
    .min(6, "Código curto demais")
    .required("Entre com o código do condomínio"),
});

const Places: React.FC = () => {
  const [alertSeverity, setAlertSeverity] = useState<AlertSeverity>(),
    [places, setPlaces] = useState<Array<Place>>([]),
    [loading, setLoading] = useState(false),
    [showAlert, setShowAlert] = useState(false),
    [showEnter, setShowEnter] = useState(false),
    [alert, setAlert] = useState("");

  const classes = useStyles();

  const history = useHistory();

  const handleAlertClose = () => {
    alterAlert();
  };

  const alterAlert = (msg?: string, severity?: AlertSeverity) => {
    if (showAlert && !msg && !severity) {
      setShowAlert(false);
    } else {
      setShowAlert(true);
      setAlert(msg || "");
      setAlertSeverity(severity);
    }
  };

  const getPlaces = async () => {
    setLoading((oldState) => !oldState);
    axios
      .get("/licensed/user/places")
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        console.log(data);
        setPlaces(data);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        alterAlert("Problema ao carregar locais", "error");
        console.log(error);
      });
  };

  useEffect(() => {
    getPlaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enterPlace = async ({ cod }: { cod: string }) => {
    axios
      .post("/licensed/place/add/user", {
        cod,
      })
      .then(() => {
        alterAlert("Local cadastrado com sucesso", "success");
        getPlaces();
      })
      .catch((error) => {
        console.log(error);
        switch (error?.response?.data?.status) {
          case 404:
            alterAlert("Local não encontrado", "warning");
            break;
          case 1:
            alterAlert("Você já está dentro do local", "warning");
            break;
          case 2:
            alterAlert("Máximo de usuário cadastrados", "warning");
            break;
          default:
            alterAlert("Problema ao entrar em local", "error");
            break;
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      cod: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      enterPlace(values);
    },
  });

  const renderSelector = useCallback(() => {
    return (
      <div>
        <Grid
          container
          direction="row"
          alignContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Typography variant="h5" className={classes.title}>
              Seus locais
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => setShowEnter(true)}
              style={{ marginBottom: 8 }}
            >
              <Add />
            </IconButton>
          </Grid>
        </Grid>
        {!places ? (
          <Typography align="center" variant="h6">
            Problema ao carregar
          </Typography>
        ) : (
          places.map((e, index) => {
            if (!e.name) {
              return <></>;
            }
            return (
              <Card style={index > 0 ? { marginTop: 10 } : {}} key={index}>
                <CardContent>
                  <Typography variant="h6">{e.name}</Typography>
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    onClick={() => {
                      history.push(`/dashboard/place/${e._id}`);
                    }}
                  >
                    Selecionar
                  </Button>
                </CardActions>
              </Card>
            );
          })
        )}
      </div>
    );
  }, [classes, places, history]);

  const handleClose = () => {
    setShowEnter(false);
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress size={50} />
      </div>
    );
  }

  return (
    <div>
      <Dialog fullScreen open={showEnter} onClose={handleClose}>
        <DialogTitle id="responsive-dialog-title">
          Entrar como administrador
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" align="center">
            Insira o código do local para entrar
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="cod"
              name="cod"
              label="Código do local"
              value={formik.values.cod}
              onChange={(e) => {
                if (e.target.value.length > 6) {
                  return;
                }
                formik.setFieldValue("cod", e.target.value.toLowerCase());
              }}
              error={formik.touched.cod && Boolean(formik.errors.cod)}
              helperText={formik.touched.cod && formik.errors.cod}
              style={{ marginTop: 10 }}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.btn}
            variant="contained"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => formik.handleSubmit()}
            color="primary"
            className={classes.btn}
            variant="contained"
          >
            Entrar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showAlert}
        onClose={handleAlertClose}
        message={alert}
      >
        <Alert onClose={handleAlertClose} severity={alertSeverity}>
          {alert}
        </Alert>
      </Snackbar>
      {renderSelector()}
    </div>
  );
};

export default Places;
