import React, { useState } from "react";

import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import {
  IconButton,
  Typography,
  Toolbar,
  AppBar,
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
  Paper,
} from "@material-ui/core";

import logOut from "functions/logOut";

import MenuIcon from "@material-ui/icons/Menu";
import ExitIcon from "@material-ui/icons/ExitToApp";

import "assets/css/main.css";

import { adminRoutes } from "routes";
import { Route as RouteInfo } from "types";

import logo from "assets/img/lockyt_menor_branco.svg";
import nav_back from "assets/img/nav_back.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    zIndex: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logoutBtn: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    minWidth: 220,
    flex: 1,
    flexDirection: "column",
    background: `url(${nav_back})`,
    backgroundSize: "cover",
  },
  content: {
    padding: "30px 15px",
    minHeight: "100vh",
    backgroundSize: "cover",
    backgroundColor: "#ddd",
  },
  container: {
    marginRight: "auto",
    marginLeft: "auto",
  },
  appBar: {
    background: `${theme.palette.primary.main}`,
    boxShadow: "0px 2px 2px rgba(12,13,14,0.5)",
  },
  listBottom: {
    position: "absolute",
    bottom: "1.5%",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  ornaments: {
    position: "absolute",
    bottom: 0,
    zIndex: 0,
  },
}));

export default function ButtonAppBar() {
  const [drawer, setDrawer] = useState(false);
  const classes = useStyles();

  let history = useHistory();

  const toggleDrawer = () => {
    setDrawer((oldState) => !oldState);
  };

  const switchRoutes = (
    <Switch>
      {adminRoutes.map((prop, key) => {
        if (prop.layout === "/dashboard") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from="/dashboard" to={`/dashboard${adminRoutes[0].path}`} />
    </Switch>
  );

  const itemClick = (route: RouteInfo) => {
    history.push(`${route.layout}${route.path}`);
    setDrawer(false);
  };

  const renderList = () => (
    <List className={classes.list}>
      <Paper style={{ margin: 10 }}>
        {adminRoutes.map((route, index) =>
          route.isVisible === false ? null : (
            <ListItem button key={index} onClick={() => itemClick(route)}>
              <ListItemIcon>
                {typeof route.icon === "string" ? (
                  <Icon style={{ color: "#000000DE" }}>{route.icon}</Icon>
                ) : (
                  <route.icon style={{ color: "#000000DE" }} />
                )}
              </ListItemIcon>
              <ListItemText primary={route.name} />
            </ListItem>
          )
        )}
      </Paper>
      <div className={classes.listBottom}>
        {/* <Divider className={classes.listBottomDivider} /> */}
        <a href="https://lockyt.com">
          <img
            src={logo}
            alt="Logo Lockyt"
            style={{
              width: "10rem",
              position: "absolute",
              zIndex: 1,
              bottom: 10,
              left: "14%",
            }}
            className={classes.ornaments}
          />
        </a>
      </div>
    </List>
  );

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              style={{
                textTransform: "none",
                fontFamily: "NoirPro",
                fontSize: "1.8rem",
              }}
              className={classes.title}
            >
              Lockyt
            </Typography>
            <IconButton
              edge="start"
              className={classes.logoutBtn}
              color="inherit"
              aria-label="menu"
              onClick={logOut}
            >
              <ExitIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer anchor="left" open={drawer} onClose={toggleDrawer}>
          {renderList()}
        </Drawer>
      </div>
      <div className={classes.content}>
        <div className={classes.container}>{switchRoutes}</div>
      </div>
    </>
  );
}
