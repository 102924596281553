import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    gradient: {
      border: 0,
      height: "auto",
      margin: 0,
      display: "inherit",
      padding: 0,
      position: "relative",
      minHeight: "100vh",
      alignItems: "center",
      background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    },
    leftSide: {
      maxWidth: 400,
      height: "100vh",
      order: 2,
      borderRadius: 0,
    },
    cardContent: {
      marginTop: "10%",
    },
    title: {
      textAlign: "center",
      fontFamily: "Nunito Sans",
    },
    inputs: {},
    btnLogin: {},
    logo: {
      position: "absolute",
      width: "31vw",
      right: "20%",
      top: "30%",
      bottom: "20%",
    },
  })
);
export default useStyles;
