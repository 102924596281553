import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import CustomAlert from "components/CustomAlert";
import MaterialTable from "material-table";
import axios from "config/axios";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useParams } from "react-router";
import { DoorParams, LicensedDoor, Log } from "types";

import useStyles from "./styles";
import { calculatePrice } from "functions/calculatePrice";

const Door: React.FC = () => {
  const [door, setDoor] = useState<LicensedDoor>(),
    [loading, setLoading] = useState(false);

  const classes = useStyles();

  const tableRef = useRef<any>();
  const alertRef = useRef<any>();

  const history = useHistory();

  const params = useParams<DoorParams>();
  const { doorId } = params;

  const getData = useCallback(async () => {
    setLoading(true);
    axios
      .get(`/licensed/door/${doorId}`)
      .then(({ data }) => {
        setLoading(false);
        setDoor(data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            alertRef?.current?.alterAlert(
              "Problema ao carregar dados da porta",
              "error"
            );
            break;
        }
      });
  }, [doorId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const alterStateDoor = async () => {
    setLoading((oldState) => !oldState);
    axios
      .put("/licensed/door/alter/state", {
        doorId,
      })
      .then(() => {
        alertRef?.current?.alterAlert(
          door?.state === "working" ? "Porta bloqueada" : "Porta desbloqueada",
          "success"
        );
        getData();
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            alertRef?.current?.alterAlert(
              "Problema ao bloquear a porta",
              "error"
            );
            break;
        }
      });
  };

  const openDoor = async () => {
    setLoading((oldState) => !oldState);
    //Deve perguntar quando a porta estiver ocupada
    axios
      .post("/licensed/door/open", {
        doorId,
      })
      .then(() => {
        getData();
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            alertRef?.current?.alterAlert(
              "Problema ao bloquear a porta",
              "error"
            );
            break;
        }
      });
  };

  const endRent = async () => {
    //Deve perguntar quando a porta estiver ocupada
    setLoading((oldState) => !oldState);
    axios
      .put("/licensed/door/end", {
        doorId,
      })
      .then(() => {
        getData();
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            alertRef?.current?.alterAlert(
              "Problema ao bloquear a porta",
              "error"
            );
            break;
        }
      });
  };

  const sendPush = async () => {
    setLoading((oldState) => !oldState);
    axios
      .post("/licensed/door/send/push", {
        doorId,
      })
      .then(() => {
        getData();
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            alertRef?.current?.alterAlert(
              "Problema ao bloquear a porta",
              "error"
            );
            break;
        }
      });
  };

  const formatMonthRevenues = useMemo(() => {
    if (!door) return calculatePrice(0);
    return calculatePrice(door?.monthRevenues || 0);
  }, [door]);

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CustomAlert ref={alertRef} />
        <CircularProgress size={40} />
      </div>
    );
  }

  return (
    <div>
      <IconButton
        onClick={() => {
          history.goBack();
        }}
        className={classes.backArrowBtn}
      >
        <ArrowBack />
      </IconButton>
      <CustomAlert ref={alertRef} />
      <Paper className={classes.paper}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h5">
              Porta: {door?.cod} / Armário: {door?.board?.name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Está alugada por:{" "}
              <b>{door?.user?.name ? door?.user?.name : "-"}</b>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Faturamento do mês: <b>{formatMonthRevenues}</b>
            </Typography>
          </Grid>
          <Grid item>
            <MaterialTable
              options={{ exportButton: true, grouping: true, search: true }}
              tableRef={tableRef}
              actions={[
                {
                  icon: "refresh",
                  tooltip: "Recarregar dados",
                  isFreeAction: true,
                  onClick: () =>
                    tableRef.current && tableRef?.current?.onQueryChange(),
                },
              ]}
              style={{ fontFamily: "Nunito Sans" }}
              columns={[
                {
                  title: "_id",
                  field: "_id",
                  type: "string",
                  hidden: true,
                  editable: "never",
                },
                {
                  title: "Usuário",
                  field: "user.name",
                  type: "string",
                  editable: "never",
                },
                {
                  title: "Início",
                  field: "rented",
                  type: "datetime",
                  editable: "never",
                  defaultSort: "desc",
                },
                {
                  title: "Término",
                  field: "ended",
                  type: "datetime",
                  editable: "never",
                },
                {
                  title: "Valor",
                  type: "string",
                  editable: "never",
                  render: (data: Log) => {
                    return (
                      <Typography>{calculatePrice(data.finalPrice)}</Typography>
                    );
                  },
                },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let { page, pageSize, orderBy, orderDirection, search } =
                    query;
                  axios
                    .post(`/licensed/door/logs`, {
                      page,
                      pageSize,
                      orderBy,
                      orderDirection,
                      search,
                      doorId: params.doorId,
                    })
                    .then(({ data }) => {
                      resolve({
                        data: data.docs,
                        page: data.page - 1,
                        totalCount: data.totalDocs,
                      });
                    })
                    .catch((error) => {
                      console.log(error);
                      switch (error.response.data.status) {
                        default:
                          alertRef?.current?.alterAlert(
                            "Problema ao carregar dados",
                            "error"
                          );
                          break;
                      }
                      reject("Problema ao carregar dados");
                    });
                })
              }
              localization={{
                header: {
                  actions: "Ações",
                },
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "registros",
                  labelRowsPerPage: "Registros por página:",
                  firstAriaLabel: "Primeira",
                  firstTooltip: "Primeira",
                  previousAriaLabel: "Anterior",
                  previousTooltip: "Anterior",
                  nextAriaLabel: "Próxima",
                  nextTooltip: "Próxima",
                  lastAriaLabel: "Última",
                  lastTooltip: "Última",
                },
                toolbar: {
                  exportTitle: "Exportar",
                  exportAriaLabel: "Exportar",
                  exportCSVName: "Exportar CSV",
                  exportPDFName: "Exportar PDF",
                  searchTooltip: "Pesquisar",
                  searchPlaceholder: "Pesquisar",
                },
                body: {
                  emptyDataSourceMessage: "Sem informações",
                },
                grouping: {
                  groupedBy: "Agrupado por:",
                  placeholder: "Arraste um cabeçalho para agrupar",
                },
              }}
              title={`Histórico da porta`}
            />
          </Grid>
          <Grid item style={{ marginTop: 20 }}>
            <Paper className={classes.paper}>
              <Typography variant="h5" style={{ marginBottom: 10 }}>
                Controle
              </Typography>
              <Grid
                container
                direction="row"
                justify="space-around"
                spacing={2}
              >
                <Grid item xs={6} md={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={alterStateDoor}
                  >
                    {door?.state === "working"
                      ? "Bloquear porta"
                      : "Liberar porta"}
                  </Button>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={openDoor}
                  >
                    Abrir a porta
                  </Button>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={!door?.rented && !door?.user}
                    onClick={endRent}
                  >
                    Liberar porta
                  </Button>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={!door?.rented && !door?.user}
                    onClick={sendPush}
                  >
                    Avisar usuário
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Door;
