import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: 20,
    },
    backArrowBtn: {
      backgroundColor: "white",
      marginBottom: 10,
    },
  })
);
export default useStyles;
