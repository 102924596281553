import React, { useState, useRef } from "react";

import { Tab, AppBar, IconButton, Tabs } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router";

import useStyles from "./styles";
import CustomAlert from "components/CustomAlert";
import TabPanel from "components/TabPanel";

import PlaceDoors from "components/PlaceDoors";
import PlaceStatistics from "components/PlaceStatistics";

const Boards: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const classes = useStyles();

  const alertRef = useRef<any>();

  const theme = useTheme();

  const history = useHistory();

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
      <CustomAlert ref={alertRef} />
      <IconButton
        onClick={() => {
          history.goBack();
        }}
        className={classes.backArrowBtn}
      >
        <ArrowBack />
      </IconButton>
      <div className={classes.tabsContainer}>
        <AppBar position="static" color="default">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Portas" />
            <Tab label="Estatística" />
          </Tabs>
        </AppBar>
        <div>
          <TabPanel value={selectedTab} index={0} dir={theme.direction}>
            <PlaceDoors alterAlert={alertRef?.current?.alterAlert} />
          </TabPanel>
          <TabPanel value={selectedTab} index={1} dir={theme.direction}>
            <PlaceStatistics alterAlert={alertRef?.current?.alterAlert} />
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

export default Boards;
